import { env } from '$env/dynamic/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://aa84307c067d31971ee3fcb70e5118d9@o4506909753540608.ingest.us.sentry.io/4506936761384960',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  environment: env.PUBLIC_APP_ENV,
});

export const handleError = handleErrorWithSentry();
